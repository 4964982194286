export default [
  {
    title: 'Supplier',
    route: 'supplier.index',
    icon: 'BoxIcon',
  },
  {
    title: 'Customer',
    route: 'customer.index',
    icon: 'UserIcon',
  },
  {
    title: 'Berlangganan',
    route: 'subscription.index',
    icon: 'FilePlusIcon',
  },
  // {
  //   title: 'Master Produk',
  //   route: 'master.product.index',
  //   icon: 'HardDriveIcon',
  // },
  {
    title: 'Bank',
    route: 'master-bank.index',
    icon: 'CreditCardIcon',
  },
  // {
  //   title: 'Career',
  //   route: 'career.index',
  // },
  // {
  //   title: 'Contact Us',
  //   route: 'contact_us.index',
  // },
  {
    title: 'Admin Users',
    route: 'master-admin.index',
    icon: 'DatabaseIcon',
  },
];
